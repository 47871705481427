import Mock from "../mock";

const database = {
  information: {
    name: 'Bapto Closter',
    aboutContent: "I am a frontend developer. I can provide clean code and pixel perfect design. I also make website and mobileapp more & more interactive with animations.",
    age: 24,
    phone: '',
    nationality: 'French',
    language: 'English, French',
    email: '',
    address: 'Paris, France',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
  },
  services: [
    {
      title: "Web Design",
      icon: 'color-pallet',
      details: "Good UX/UI according to the project needs."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Great reponsive web apps with clean code & seo best practices."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Mobile apps working on all device types with amazing annimations."
    }
  ],
  reviews: [],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 75
    },
    {
      title: "React Native",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Computer Science",
      subtitle: "I was 15 years old, the day when me and my best friend went to an internet café for the first time. It was my first time ever discovering the world wide web, i had these confused & amazing feelings at the same time because what we were in front of was so magical and above all a new world for us that we don't know. Having these amazing tools to find anything and learn everything, only by using a computer, was the first and most important reason that gave me the passion that made me deeply in love with the world of computing.",
      imageUrl: "/images/portfolio-image-1.jpg",
    },
    {
      id: 2,
      title: "Web & Mobile",
      subtitle: "Year after year, I was very attached to the Internet world and I started to wonder how all these apps, with diverse content and rich in information, are public and accessible to anyone who wants to visit and learn from them? It was then that I discovered web and mobile developments and started building my own websites and mobile apps following best practices recommended by experts in the field. So, now I am so excited with the evolution that is experienced every day in the world of web & mobile apps.",
      imageUrl: "/images/portfolio-image-2.jpg",
      
    },
    {
      id: 3,
      title: "UI/UX",
      subtitle: "What else? We always have this question, it's the one that made me wonder why some apps are so easy to understand and so flexible to use than others. With some research on Google I found the UI / UX world which was so interesting to explore because its interest is how to make apps so user friendly based on user experience. Now, I'm always checking out new tools or technologies that can help me increase the user experience of my apps.",
      imageUrl: "/images/portfolio-image-3.jpg",
    },
    {
      id: 4,
      title: "Sport",
      subtitle: "Most of the people in the world are passionate about some kind of sports game and that's one of the beautifull truths in our world beause Sport is so important to live a healthier life. So for me, the main games i love are Football, Tennis, Ping Pong, SpikeBall, Cycling, Hiking, Billiard and Baby Foot, i kind of having much joys playing it especially the competition part and ofcourse i can't ignore that they give me opportunities to meet new people and create new connections with them.",
      imageUrl: "/images/portfolio-image-4.jpg",
    },
    {
      id: 5,
      title: "Trip",
      subtitle: "Once upon a time I was with my family talking about some practices that we can use to meditate and then my big brother came up with this epic idea that travel is the right solution according to his experiences of course. After that I decided to give it a try and my first trip was to Norway, especially in the city of OSLO, I had the most meaningful week of my life full of memories and new experiences with amazing people, all of that gave me toughts about how meaningfull are all these moments we live without noticing it and that there are always new things to discover, new ideas to discuss and new amazing people to know, which made me meet my soul in a unique universe having a peace conversation concluding it with some amazing ideas i can use to help others starting a new story titled << There is always a solution >>",
      imageUrl: "/images/portfolio-image-5.jpg",
    },
    {
      id: 6,
      title: "Writing",
      subtitle: "I am a big fan of travel, but you should know that a tripper without a pen is like a monk in the middle of PARIS who wakes up to work instead of doing his meditation routine. I always have my pen and my note especially when I travel, I need to translate my ideas and thoughts into words before I forget them because our memory does not remember everything, it needs support. I also create stories with imaginary characters basing on my routine life, my travels and my dreams.",
      imageUrl: "/images/portfolio-image-6.jpg",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Frontend Developer",
        details: "Developed a web and mobile banking apps by leading a team of 5 members."
      },
      {
        id: 2,
        year: "2016 - 2018",
        position: "Frontend Developer",
        details: "Design and develop a social and gaming mobile application in which users can follow and support their football teams."
      },
      {
        id: 3,
        year: "2014 - 1016",
        position: "UI/UX Designer",
        details: "UX/UI for a web & mobile apps to offer users an online transport service."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2013 - 2016",
        graduation: "Master of Science",
      },
      {
        id: 2,
        year: "2011 - 2013",
        graduation: "Bachelor of Science",
      },
      {
        id: 3,
        year: "2010 - 2011",
        graduation: "Higher Schoold Graduation",
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Sport & Trip for effective meditation.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-sport-trip-for-effective-meditation.md',
      createDay: "10",
      createMonth: 'January',
      createYear: "2021"
    },
    {
      id: 2,
      title: 'Javascript a revolution in computing.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/javascript-a-revolution-in-computing.md',
      createDay: "13",
      createMonth: 'January',
      createYear: "2021"
    },
    {
      id: 3,
      title: 'UI/UX to design user-friendly applications.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-user-friendly-apps.md',
      createDay: "26",
      createMonth: 'January',
      createYear: "2021"
    },
    {
      id: 4,
      title: 'Travelling can increase and improve your skills.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/travelling-increase-improve-your-skills.md',
      createDay: "07",
      createMonth: 'February',
      createYear: "2021"
    },
    {
      id: 5,
      title: 'Angular VS ReactJs VS VuJs.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/angular-vs-reactjs-vs-vujs.md',
      createDay: "25",
      createMonth: 'February',
      createYear: "2021"
    },
    {
      id: 6,
      title: 'Animes / Movies / Shows you can learn from.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/animes-movies-shows-you-can-learn-from.md',
      createDay: "03",
      createMonth: 'Mars',
      createYear: "2021"
    },
  ],
  contactInfo: {}
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});